<template>
  <div class="container">
    <TopHeader title="申请详情"></TopHeader>
    <div v-if="applyDetail" style="flex: 1;width: 100%;overflow: auto;">
      <div style="background: #fff;margin-top: 10px;">
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;box-sizing: border-box;padding: 16px;">
          <div style="color: #333;">家属姓名</div>
          <div style="color: #666;">{{applyDetail.name}}</div>
        </div>
        <CommonBorder></CommonBorder>
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;box-sizing: border-box;padding: 16px;">
          <div style="color: #333;">家属手机号</div>
          <div style="color: #666;">{{applyDetail.phone}}</div>
        </div>
        <CommonBorder></CommonBorder>
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;box-sizing: border-box;padding: 16px;">
          <div style="color: #333;">审核结果</div>
          <div>
            <van-tag v-if="applyDetail.status === 0" color="#FFA900" size="medium">审核中</van-tag>
            <van-tag v-if="applyDetail.status === 1" color="#00B893" size="medium">同意</van-tag>
            <van-tag v-if="applyDetail.status === 2" color="#ED3944" size="medium">拒绝</van-tag>
          </div>
        </div>
        <CommonBorder></CommonBorder>
        <div v-if="applyDetail.status === 2" style="display: flex;flex-direction: column;box-sizing: border-box;padding: 16px;">
          <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
            <div style="color: #333;">备注</div>
            <div style="color: #666;" v-if="!applyDetail.remark">无</div>
          </div>
          <div v-if="applyDetail.remark" style="margin-top: 15px;">
            <van-field
              v-model="applyDetail.remark"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              disabled
              show-word-limit
            />  
          </div>
        </div>
        <CommonBorder></CommonBorder>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import { Toast } from "vant";
import store from '@/store'
import { getUserMemberInfo } from '@/api/family'
import TopHeader from '@/components/topHeader/index.vue'
import CommonBorder from '@/components/commonBorder/index.vue'
export default {
  components: {
    TopHeader,
    CommonBorder
  },
    data() {
      return {
        cancel,
        title: '',
        apiUrl: process.env.VUE_APP_BASE_API,
        headers: {
          Authorization: "Bearer " + store.getters.token,
        },
        memberId: null,
        applyDetail: {},
      };
    },

    created() {
      const { item } = this.$route.query
      if (item) {
        this.$set(this, 'applyDetail', item)
      }
    },

    computed: {
      isAndroid() {
        return navigator.userAgent.indexOf("Android") > -1;
      },
    },

    methods: {
      toHome() {
        this.$router.back();
      },
    }
  }
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.van-cell{
  // border: 1px dashed #666;
  background: #f2f2f2;
}
</style>